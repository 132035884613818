<template>
   <div class="app-container menus">
       <el-form :model="queryParams" v-hasPermi="['system:school:list']" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" style="width: 100%;height: 0.5%;text-align: left;">
           <el-form-item label="学校名称" prop="schoolName">
               <el-input
                       v-model="queryParams.schoolName"
                       placeholder="请输入学校名称"
                       clearable
                       size="small"
                       style="width: 240px;"
                       @keyup.enter.native="handleQuery"
               />
           </el-form-item>
           <el-form-item label="状态" prop="status">
               <el-select
                       v-model="queryParams.status"
                       placeholder="操作状态"
                       clearable
                       size="small"
                       style="width: 240px"
               >
                   <el-option
                           v-for="dict in schoolStatus"
                           :key="dict.id"
                           :label="dict.value"
                           :value="dict.id"
                   />
               </el-select>
           </el-form-item>
           <el-form-item>
               <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-hasPermi="['system:school:list']">搜索</el-button>
               <el-button icon="el-icon-refresh" size="mini" @click="resetQuery" v-hasPermi="['system:school:list']">重置</el-button>
           </el-form-item>
       </el-form>
       <el-row :gutter="10" class="mb8">
           <el-col :span="1.5">
               <el-button
                       type="primary"
                       plain
                       icon="el-icon-plus"
                       size="mini"
                       @click="handleAdd"
                       v-hasPermi="['system:school:add']"
               >新增</el-button>
           </el-col>
           <el-col :span="1.5">
               <el-button
                       type="primary"
                       plain
                       icon="el-icon-plus"
                       size="mini"
                       @click="handleAddS"
                       v-hasPermi="['system:school:add']"
               >批量新增</el-button>
           </el-col>
       </el-row>

       <el-table
               v-if="refreshTable"
               v-loading="loading"
               :data="schoolList"
               style="width: 100%;margin-bottom: 20px;"
               row-key="id"
               :default-expand-all ="isExpandAll"
               :header-cell-style="{'text-align':'center'}"
               :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
           <el-table-column
                   prop="title"
                   label="学校名称"
                   :show-overflow-tooltip="true"
                   algin="center"
                   width="350">
           </el-table-column>
           <el-table-column
                   prop="type"
                   label="类型"
                   align="center"
                   width="180">
               <template slot-scope="scope">
                   <span v-if="scope.row.type === 1">幼儿园</span>
                   <span v-if="scope.row.type === 2">小学</span>
                   <span v-if="scope.row.type === 3">中学</span>
                   <span v-if="scope.row.type === 4">高中</span>
                   <span v-if="scope.row.type === 5">职高</span>
                   <span v-if="scope.row.type === 6">其他</span>
               </template>
           </el-table-column>
           <el-table-column
                   prop="displayorder"
                   label="排序"
                   align="center"
                   width="180">
           </el-table-column>
           <el-table-column
                   prop="status"
                   label="状态"
                   align="center"
                   width="180">
               <template slot-scope="scope">
                   <el-tag v-if="scope.row.status === '1'">正常</el-tag>
                   <el-tag type="danger" v-else>停用</el-tag>
               </template>
           </el-table-column>
           <el-table-column
                   prop="count"
                   label="用户数量"
                   align="center"
                   width="180">
               <template slot-scope="scope">
<!--                   <el-tag v-if="scope.row.count !== 0 ">标签一</el-tag>-->
                   <el-button type="text" v-if="scope.row.count !== 0 ">{{scope.row.count}}</el-button>
               </template>
           </el-table-column>
           <el-table-column label="操作"  align="center">
               <template slot-scope="scope">
                   <el-button
                           size="mini"
                           type="text"
                           icon="el-icon-edit"
                           @click="handleUpdate(scope.row)"
                           v-hasPermi="['system:school:edit']"
                   >修改</el-button>
                   <el-button
                           size="mini"
                           type="text"
                           icon="el-icon-plus"
                           @click="handleAdd(scope.row)"
                           v-hasPermi="['system:school:add']"
                   >新增</el-button>
                   <el-button
                           v-if="scope.row.parentId !== 0"
                           size="mini"
                           type="text"
                           icon="el-icon-delete"
                           @click="handleDelete(scope.row)"
                           v-hasPermi="['system:school:delete']"
                   >删除</el-button>
               </template>
           </el-table-column>
       </el-table>
       <!-- 添加或修改对话框 -->
       <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
           <el-form ref="schoolForm" :model="schoolForm" :rules="rules" label-width="80px" style="width: 100%;height: 100%">
               <el-form-item label="上级" prop="parentid">
                   <treeselect v-model="schoolForm.parentid" :options="schoolOptions" :normalizer="normalizer" placeholder="选择上级" />
               </el-form-item>
               <el-form-item label="学校名称" prop="title">
                   <el-input v-model="schoolForm.title" placeholder="请输入学校名称" maxlength="20" autocomplete="off" />
               </el-form-item>
              
               <el-form-item label="类型" >
                    <el-select v-model="schoolForm.type" placeholder="请选择" style="width:100%">
                        <el-option
                        v-for="item in stateType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="显示排序" prop="displayorder">
                    <el-input-number v-model="schoolForm.displayorder" controls-position="right" :min="0" autocomplete="off" style="width:100%" />
                </el-form-item>
               <el-form-item label="部门状态" label-width="80px" prop="status" autocomplete="off">
                   <el-radio-group v-model="schoolForm.status" >
                       <el-radio :label="'1'">正常</el-radio>
                       <el-radio :label="'0'">停用</el-radio>
                   </el-radio-group>
               </el-form-item>
           </el-form>

           <div slot="footer" class="dialog-footer">
               <el-button type="primary" @click="submitForm">确 定</el-button>
               <el-button @click="cancel">取 消</el-button>
           </div>
       </el-dialog>

       <!-- -->
       <el-dialog :title="title" :visible.sync="openAdd" width="600px" append-to-body @close ="editDialogClosed">
           <el-form ref="addSchools" :model="addSchools" :rules="rules"  label-width="80px" style="width: 100%;height: 100%">
               <el-form-item label="上级" prop="parentid">
                   <treeselect v-model="addSchools.parentid" :options="schoolOptions" :normalizer="normalizer" placeholder="选择上级" />
               </el-form-item>
               <el-form-item label="学校名称" prop="title">
                   <el-input type="textarea" v-model="addSchools.title" placeholder="请输入学校名称" autocomplete="off" :rows="10"></el-input>
                   <span style="color: #999999;">每个学校之间请以','隔开</span>
               </el-form-item>
               <el-form-item label="类型" >
                    <el-select v-model="addSchools.type" placeholder="请选择" style="width:100%">
                        <el-option
                        v-for="item in stateType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
           </el-form>

           <div slot="footer" class="dialog-footer">
               <el-button type="primary" @click="submitForms">确 定</el-button>
               <el-button @click="cancel">取 消</el-button>
           </div>
       </el-dialog>
   </div>
</template>

<script>

import { schoolList,queryBumenById,addSchool,addBumenList,updateBymenById,deleteByBumenId }  from '@/api/user/shcool'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {insertDept, updateDept} from "@/api/system/dept";

    export default {
        name: "School",
        components: { Treeselect },
        data() {
            return {
                // 是否显示搜索框
                showSearch: true,
                loading: true,
                // 是否重新加载数据
                refreshTable: true,
                // 表格数据
                schoolList: [],
                // 学校树选项
                schoolOptions: [],
                // 状态
                schoolStatus:[{id:1,value: '正常'},{id: 0,value: '停用'}],
                // 弹出层标题
                title: "",
                open:false,
                openAdd: false,
                // 是否展开，默认全部展开
                isExpandAll: false,
                // 查询参数
                queryParams: {
                    schoolName: undefined,
                    status: undefined,
                },
                schoolForm: {
                    id: undefined,
                    title:undefined,
                    parentid:undefined,
                    displayorder: undefined,
                    status: '1',
                    type:undefined
                },
                stateType:[
                    {
                        value: '1',
                        label: '幼儿园'
                    },
                    {
                        value: '2',
                        label: '小学'
                    },
                    {
                        value: '3',
                        label: '中学'
                    },
                    {
                        value: '4',
                        label: '高中'
                    },
                    {
                        value: '5',
                        label: '职高'
                    },
                    {
                        value: '6',
                        label: '其他'
                    },
                ],
                addSchools: {
                    title: undefined,
                    parentid: undefined,
                    type:undefined
                },
                rules: {
                    parentid: [
                        {required: true, message: "上级不能为空", trigger: "blur"}
                    ],
                    title: [
                        {required: true, message: "学校名称不能为空", trigger: "blur"}
                    ],
                    
                }
            }
        },
        created(){
            this.getList();
        },
        methods: {
            /** 搜索按钮操作 */
            handleQuery() {
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.getList();
            },
            normalizer(node) {
                if (node.children && !node.children.length) {
                    delete node.children;
                }
                return {
                    id: node.id,
                    label: node.title,
                    children: node.children
                };
            },
            getList(){
                this.loading = true;
                schoolList(this.queryParams).then(res =>{
                    if(sessionStorage.getItem('schoolIdList') == 'undefined'){
                        this.schoolList = this.handleTree(res.data, 'id', 'parentid')
                        this.loading = false;
                    }else{
                        let arr = sessionStorage.getItem('schoolIdList').split(",")
                        let schoolArr = []
                        arr.forEach((item) => {
                            res.data.forEach((school) => {
                            if(school.id == item) schoolArr.push(school)
                            })
                        })
                        this.schoolList = this.handleTree(schoolArr, 'id', 'parentid')
                        this.loading = false;
                    }
                })
            },
            // 表单重置
            reset() {
                this.schoolForm= {
                        id: undefined,
                        title:undefined,
                        parentid:undefined,
                        displayorder: 0,
                        status: '1'
                };
                this.resetForm("schoolForm");
                this.schoolOptions = []
            },
            handleUpdate(row){
                this.reset();
                this.title = '修改学校';
                schoolList().then(res => {
                    const school = { id: 0, title: '主类目', children: [] };
                    school.children = this.handleTree(res.data,'id','parentid');
                    this.schoolOptions.push(school);
                });
                const schoolId = row.id;
                queryBumenById({schoolId}).then(res => {
                    // console.log(res.data);
                    this.schoolForm = res.data;
                    if(this.schoolForm.type != undefined){
                        this.schoolForm.type = this.schoolForm.type+""
                    }
                    
                });
                this.open = true;
            },
            handleAdd(row){
                this.reset();
                this.title = '新增学校';
                if (row !== undefined){
                    this.schoolForm.parentid = row.id;
                }
                schoolList().then(res => {
                    const school = { id: 0, title: '主类目', children: [] };
                    school.children = this.handleTree(res.data,'id','parentid');
                    this.schoolOptions.push(school);
                });
                this.open = true;
            },
            handleAddS(){
                this.resetForm("addSchools");
                this.schoolOptions = [];
                this.title = '批量新增';
                schoolList().then(res => {
                    const school = { id: 0, title: '主类目', children: [] };
                    school.children = this.handleTree(res.data,'id','parentid');
                    this.schoolOptions.push(school);
                });
                this.openAdd = true;
            },
            handleDelete(row){
                const bumenId = row.id;
                this.$confirm('此操作将删除 ' + row.title + ' 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteByBumenId({bumenId}).then(res => {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        this.getList();
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });          
                });
                
            },
            submitForm() {
                this.$refs['schoolForm'].validate(valid => {
                    if (valid){
                        const form = this.schoolForm
                        if (form.id !== undefined){
                            updateBymenById(form).then(res => {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success',
                                });
                                this.open = false;
                                this.getList();
                            })
                        }else {
                            addSchool(form).then(res => {
                                this.$message({
                                    message: "添加成功",
                                    type: 'success'
                                });
                                this.open = false;
                                this.getList();
                            })
                        }
                    }
                })
            },
            // 批量新增
            submitForms(){
                this.$refs['addSchools'].validate(valid =>{
                    if (valid){
                        addBumenList(this.addSchools).then(res => {
                            if(res.status === 200){
                                this.$message({
                                    message: '新增成功',
                                    type: 'success',
                                });
                                this.addSchools.type = undefined
                                this.openAdd = false;
                                this.getList();
                            }
                        });
                    }
                })
            },
            editDialogClosed(){
                this.$refs.addSchools.resetFields() // 清空表单
                // this.$refs['addSchools'].resetFields() // 清空表单
            },
            cancel() {
                this.open = false;
                this.openAdd = false
                this.$refs['addSchools'].resetFields() // 清空表单
            }
        }
    }
</script>

<style scoped>
    .app-container {
        padding: 20px;
    }
    .mb8 {
        margin: 0 -5px 8px;
    }
</style>